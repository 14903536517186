import React from "react"
// import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const ProximosEventos = () => (
	<Layout>

		<SEO title="Próximos eventos" />

		<div className="container">
			<h1>Próximos eventos</h1>
			<p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ea voluptatem consequuntur amet provident esse ratione, nulla distinctio soluta vitae unde nesciunt dignissimos doloribus, voluptatibus quidem minima a ipsum. Iusto, tenetur?</p>
		</div>

	</Layout>
)

export default ProximosEventos
